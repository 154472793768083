// 
// user.js
// Use this to write your custom JS
//
import fontawesomed from '@fortawesome/fontawesome-free/js/fontawesome'
import fontawesomes from '@fortawesome/fontawesome-free/js/solid'
import fontawesomer from '@fortawesome/fontawesome-free/js/regular'
import fontawesomeb from '@fortawesome/fontawesome-free/js/brands'


import 'datatables.net-bs5'                             // datatables
import 'datatables.net-fixedcolumns-bs5'

// no idea why but this is all needed for datatables
require('jquery');
import $ from 'jquery';

require('lightweight-charts');
import { createChart } from "lightweight-charts";

window.createChart = createChart;
window.jQuery = $;
window.$ = $;


// cookie consent function and listeners
window.cookie_consent_function = function () {
    var now = new Date();
    now.setTime(now.getTime() + (3600 * 1000)*8760 );  // 8760 hours in year         
    document.cookie = "xploras_cookie_consent=true;expires="+now.toUTCString()+";path=/;secure";
};

// cookie consent function and listeners
window.toggle_favorite = function (element, ticker) {
    var icon_fa_prefix = element.querySelector('.favorite-toggle-icon').getAttribute('data-prefix');
    // var icon_status = element.querySelector('.favorite-toggle-icon').getAttribute('data-icon');

    // if (icon_status != 'spinner'){ // only do shit if we are not loading...
        // console.log("will do shit now...")
        // set loading animation for untill done
        // element.querySelector('.favorite-toggle-icon').setAttribute('data-prefix', 'fas');
        // element.querySelector('.favorite-toggle-icon').classList.add('fa-pulse');
        // element.querySelector('.favorite-toggle-icon').setAttribute('data-icon', 'spinner');

    // proceed only if the svg doesnt have 'locked' class
    if (element.querySelector('.favorite-toggle-icon').classList.contains('locked'))
        return
    // lock the svg
    element.querySelector('.favorite-toggle-icon').classList.add('locked');

    if (icon_fa_prefix === 'fas') {
            $.ajax({ 
                url: '/user/api/fav_toggle', 
                type: 'GET',
                data: {
                    "fav_action": "rem",
                    "ticker": ticker,
                },
                success: function(response){
                    element.querySelector('.favorite-toggle-icon').classList.remove('locked');
                    element.querySelector('.favorite-toggle-icon').setAttribute('data-prefix', 'far');
                    // element.querySelector('.favorite-toggle-icon').setAttributeNS(null, 'data-icon', 'heart');
                } 
            });
    
        } else {
            
            $.ajax({ 
                url: '/user/api/fav_toggle', 
                type: 'GET', 
                data: {
                    "fav_action": "add",
                    "ticker": ticker,
                },
                success: function(response){
                    element.querySelector('.favorite-toggle-icon').classList.remove('locked');
                    element.querySelector('.favorite-toggle-icon').setAttribute('data-prefix', 'fas');
                    // element.querySelector('.favorite-toggle-icon').setAttributeNS(null, 'data-icon', 'heart');
                } 
            });
        }

    // }
};

// <svg class="svg-inline--fa fa-heart fa-w-16 favorite-toggle" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path></svg>
// <svg class="svg-inline--fa fa-heart fa-w-16 favorite-toggle" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>